export default function Index() {
  return (
    <div style={{ fontFamily: "system-ui, sans-serif", lineHeight: "1.4" }}>
      <strong>What is NODΞS?</strong>
      <br />
      <p>
        NODΞS is a mirrored on-chain, interactive, generative art experience. Each NODΞ is infinitely animated (no loops), and all begin from a "blank canvas".
      </p>
      <p>
        Aside from the art itself, users will be able to use a unique breeding mechanic, called "fusing," that involves a "trait token" system. Your piece will generate a set amount of non-tradable trait tokens that can be spent to influence the outcome of a secondary piece or "fused piece". This essentially allows you to customize (with limitations) your art in your own way to achieve an outcome that either you desire, is deemed rare or unique, or to just have fun with seeing what you can come up with.
      </p>
      <p>
        Currently, there is no set road map beyond what has been described. However, this project will be supported long term. 
      </p>
      <p>
      Our goal is to provide a unique art experience for people who love generative art, and are interested in furthering innovation and education in on-chain NFTs.
      </p>
      <strong>Wen Mint?</strong>
      <br />
      <p>
        We sold out on March 06, 2022 - 11:00PM PST. Secondary is open on <a href="https://opensea.io/collection/nodes-onchain">OpenSea</a>.
      </p>
      <strong>Mint Price / Supply</strong>
      <br />
      <p>
        .05 ETH per Node / 1024 Genesis mints.
      </p> 
      <p>
        Gas Only / 2048 Fused Nodes (read the whitepaper).
      </p>
      <strong>How to get Mintlist?</strong>
      <br />
      <p>
        Mint list closed, the project has minted out.
      </p>
    </div>
  );
}
